import React from "react";
import styles from "./social.module.css";

export const SocialIcon = () => {
  return (
    <div className={styles.wrapper}>
      <a href="https://www.facebook.com/vincentwyckoffauthor/" className={styles.a}>
        <img className={styles.social_icon} src="/assets/facebook.png" alt="" />
      </a>      
    </div>
  );
};
