import React from "react";
import styles from "./biocard.module.css";


export class BioCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = { card: {}, isLoaded: false };
  }
  componentDidMount() {
 

    fetch("https://jkubo8o7ie.execute-api.us-west-1.amazonaws.com/test/card/info", {
      method: "GET",
    })
      .then((response) => {
        return response.json(); 
      })
      .then((data) => {
        console.log(data);
        this.setState({ card: data, isLoaded: true });
      });
  }
 
  render() {
    console.log(this.state.isLoaded);
    return (
      <div className={styles.BiocardWrapper}>
        {
        
        this.state.isLoaded !== true ? <div className={styles.preloader}></div> :         
        (        
        <div className={styles.BioBody}>
          <div className={styles.BioBlurb}>
            <h3 className={styles.title}>Vincent Wyckoff</h3>
            <p className={styles.content}>{this.state.card?.content} </p>
          </div>

          
          <div className={styles.BioWrapperImage}>
            <img
              className={styles.BioCardImage}
              width="100%"
              src={this.state.card?.img}
              alt="author"
            />

        <hr className={styles.hr} />
            <p className={styles.quote}>“The world needs more wise and gentle voices like Vince Wyckoff’s.”</p>
            <p className={styles.quoted}>&mdash;Lorna Landvik</p>
          
          </div>       
        
        </div>    

        )
        }      
              
      
        
      </div>
    );
  }
}

