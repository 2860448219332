import React from "react";
import styles from "./article.module.css";

export const Article = () => {
  let url1 = "https://photos-wyckoff.s3.us-east-2.amazonaws.com/postal-article.pdf"
    return (
      <div className={styles.ArticleWrapper}>
        <div className={styles.ArticleBody}>           
          <h2 className={styles.title}>Vince was featured in the November 2022 edition of <span className={styles.spanArticle}>The Postal Record</span> magazine.</h2>  
            <p className={styles.seeWorks}><a href={url1} className={styles.seeWorks}>Click here to read the article!</a>
              </p>  
              <p>             
                <img className={styles.articleImg} alt = "Article Image" src={`https://photos-wyckoff.s3.us-east-2.amazonaws.com/img/Page+1.jpg`}/>                  
            </p>    
        </div>       
      </div>
    );
  }


