import React, { useState } from "react";
import styles from "./contact-form.module.css";
import { SolidButton } from "../../common/buttons/solidButton";
import { useFormik } from "formik";

const initialValues = {
  firstName: "",
  lastName: "",
  email: "",
  message: "",
};

export const ContactForm = () => {
  const [fetchStatus, setFetchStatus] = useState("idle"); // 'idle', 'loading', 'success', 'error'
  const formik = useFormik({
    initialValues,
    onSubmit: async (values, { resetForm }) => {
      setFetchStatus("loading");
      try {
        const response = await fetch(
          "https://jkubo8o7ie.execute-api.us-west-1.amazonaws.com/test/email",
          {
            method: "POST",
            body: JSON.stringify(values),
          }
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        setFetchStatus("success");
        alert("Thank you, your message was sent to Vince!");
        resetForm({ values: "" });
      } catch (error) {
        setFetchStatus("error");
        console.error("Exception thrown", error);
        alert(
          "Sorry, your message could not be sent. Please try contacting me through my author Facebook page by clicking the FB icon above."
        );
      }
    },
    validate: (values) => {
      const errors = {};
      if (!values.firstName) {
        errors.firstName = "Required";
      } else if (!/^(([A-Za-z]+[-]?[\s+]?)*)$/i.test(values.firstName)) {
        errors.firstName = "Please use letters";
      } else if (values.firstName.length > 15) {
        errors.firstName = "Please use less than 15 letters";
      }

      if (!values.lastName) {
        errors.lastName = "Required";
      } else if (!/^(([A-Za-z]+[-]?[\s+]?)*)$/i.test(values.lastName)) {
        errors.lastName = "Please use letters";
      } else if (values.lastName.length > 15) {
        errors.lastName = "Please use less than 15 letters";
      }

      if (!values.email) {
        errors.email = "Please provide an email adress";
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
      ) {
        errors.email = "Invalid email address";
      }

      if (!values.message) {
        errors.message = "Please type your message";
      } else if (!/^[A-Za-z0-9 !$%().-]+$/i.test(values.message)) {
        errors.message = "Please use valid characters in your message";
      }

      return errors;
    },
  });

  return (
    <form className={styles.wrapper} onSubmit={formik.handleSubmit}>
      <div className={styles.header}>Write a Note to Vince</div>
      {fetchStatus === "error" && (
        <div className={styles.errorMessage}>
          Failed to send the message. Please try again.
        </div>
      )}
      <ul>
        <li className={styles.formRow}>
          <input
            className={styles.input}
            id="firstName"
            name="firstName"
            placeholder=" First name"
            type="text"
            onChange={formik.handleChange}
            value={formik.values.firstName}
          />
          <br />
          {formik.errors.firstName ? (
            <div>{formik.errors.firstName}</div>
          ) : null}
        </li>

        <li className={styles.formRow}>
          <input
            className={styles.input}
            id="lastName"
            name="lastName"
            placeholder=" Last name"
            type="text"
            onChange={formik.handleChange}
            value={formik.values.lastName}
          />
          <br />
          {formik.errors.lastName ? <div>{formik.errors.lastName}</div> : null}
        </li>

        <li className={styles.formRow}>
          <input
            className={styles.input}
            id="email"
            name="email"
            placeholder=" Email address"
            type="email"
            onChange={formik.handleChange}
            value={formik.values.email}
          />
          <br />
          {formik.errors.email ? <div>{formik.errors.email}</div> : null}
        </li>

        <li className={styles.formMessage}>
          <textarea
            className={styles.textarea}
            id="message"
            name="message"
            placeholder="Your Message"
            type="textarea"
            onChange={formik.handleChange}
            value={formik.values.message}
          />
          <br />
          {formik.errors.message ? <div>{formik.errors.message}</div> : null}
        </li>
        <div className={styles.button}>
          <SolidButton type="submit" label="Send Message" />
        </div>
      </ul>
    </form>
  );
};
