import React from "react";
import styles from "./footer.module.css";
import { FooterMenu } from "../footer-menu";
import { SocialIcon } from "../social-icons";
//test

export const Footer = () => {
  return (
    <footer className={styles.wrapper}>
      <SocialIcon />
      <FooterMenu />
      <p className={styles.copyright}>
        &copy; Christine Marie Woolf {new Date().getFullYear()} 
      </p>
    </footer>
  );
};

