import React from "react";
import { ContactForm } from "../../common/contact-form";


export const Contact = () => {
  return (
  <div >
    <ContactForm/>
  </div>
  )
};
