import React, {useEffect, useState} from "react";
import {EventItem} from "../../common/events-item";
import styles from "./eventspage.module.css";

//So we say stores={item.stores} and that carries the whole array with it into the works-item component?

export const EventsPage = () => {
  const [events, setEvents] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(()=> {
    fetch('https://jkubo8o7ie.execute-api.us-west-1.amazonaws.com/test/events', {
    method: 'GET'
  }).then(response=> {
    return response.json();
  }).then(data => {
    setEvents(data);
    setIsLoaded(true);
  }).catch(err=> {
    console.log('received an error', err);
  })
  }, []);


  return (
    <div className={styles.events__wrapper}>
     { isLoaded !== true ? 
     (<div className={styles.preloader}></div>) :

      (
    <ul className={styles.wrapper}>
      {events.map((item) => {
        return (
          <EventItem
            id={item.id}
            key={item.id}
            anchor={item.anchor} 
          />
        )
      })}
    </ul>
    
      )}
    </div>
  )
}