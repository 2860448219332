import React from "react";
import styles from "./featured-event.module.css";
import { Link } from "react-router-dom";

export const FeaturedEvent = () => {
  return (
    <div className={styles.FeaturedWrapper}>
      <div className={styles.FeaturedBody}>
        <h2 className={styles.title}>
          Don't miss reading Vince's latest novel,{" "}
          <span className={styles.spanFeatured}>Refuge from the Sea </span>.
        </h2>
        <div>
          <h3 className={styles.title2}>Get your copy today!</h3>
        </div>
        <Link to="/works" className={styles.seeWorks}>
          Read More
        </Link>
        <p>
          <img
            className={styles.coverImg}
            alt="Event Image"
            src={`https://photos-wyckoff.s3.us-east-2.amazonaws.com/img/websitecoverJune26.png`}
          />
        </p>
      </div>
    </div>
  );
};
