import React from "react";
import { MenuData } from "../../../data/cards";
import { MenuItem } from "../menu-item";
import styles from "./footer-menu.module.css";

export const FooterMenu = () => {
  
  return (
    <ul className={styles.menu}>
   {MenuData.map((item) => {      
      return (
        <MenuItem 
        onClicked ={() => {}}
        key={item.id}
        anchor={item.anchor}
        label={item.label}
      />
      );
    })};
    </ul>
  );
  };






