import React from "react";
import styles from "./events-item.module.css";

export const EventItem = () => {
  let url2 = "https://ecrlib.org/cambridge-mn-public-library/";
  return (
    <div className={styles.EventsWrapper}>
      <div className={styles.EventsBody}>
        {/* Title */}
        <h2 className={styles.title}>Upcoming Book Events</h2>
        {/* Main Image  */}
        <img
          className={styles.evImg1}
          alt="Event"
          src={`https://photos-wyckoff.s3.us-east-2.amazonaws.com/img/grandhandvince.jpeg`}
        />

        {/* First Event  */}

        {/* Second Event  */}

        {/* Host Announcement Section */}
        <h2 className={styles.date}>Host a Book Event with Vince</h2>
        <p className={styles.content} style={{ paddingBottom: "2.5rem" }}>
          Interested in hosting a book reading and signing event? Contact me
          today through my{" "}
          <a className={styles.a} href="https://www.vincentwyckoff.com/contact">
            Contact Page,
          </a>{" "}
          or send me a message through{" "}
          <a
            className={styles.a}
            href="https://www.facebook.com/vincentwyckoffauthor/"
          >
            {" "}
            Facebook.
          </a>{" "}
          I’m available for book clubs, libraries, or other public events. I
          would love to hear from you!
        </p>
      </div>
    </div>
  );
};
