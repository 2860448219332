import React from "react";
import styles from "./store-item.module.css";

//props are id - label - name - logo - link 

export const StoreItem = (props) => {

     return (
      <li className={styles.cardBody}>
        <div>
        <a href={props.link}>
          <img className={styles.img} 
                alt = "Store Logo" 
                src={props.logo}
          />
        </a>

        </div>
      </li>     
    );
  }