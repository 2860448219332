import React from 'react';
import { WorksItem } from '../../common/work-item';
import styles from './workspage.module.css';
import { workStores } from '../../../data/cards';

export const WorksPage = () => {
	return (
		<div className={styles.works__wrapper}>
			<ul className={styles.wrapper}>
				{workStores.map((item) => {
					return (
						<WorksItem
							id={item.id}
							key={item.id}
							img={item.img}
							title={item.title}
							content={item.content}
							anchor={item.anchor}
						/>
					);
				})}
			</ul>
		</div>
	);
};
