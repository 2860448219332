import React from "react";
import {useEffect, useState} from "react";
import { StoreItem } from "../../common/store-item";  //make store-item component
//import { StoreData } from "../../../data/cards";
import styles from "./buy-page.module.css";


//So we say stores={item.stores} and that carries the whole array with it into the works-item component?

export const BuyPage = () => {

  const [storeData, setStoreData] = useState([])

  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(() => {
    fetch('https://jkubo8o7ie.execute-api.us-west-1.amazonaws.com/test/buy', {
      method: 'GET'
      }).then(response=> {
        return response.json()
      }).then(data => {
        setStoreData(data)
        setIsLoaded(true)
      }).catch(err=> {
        console.log('error', err);
      })
    },[]);
      
   
  return (
    <>
    
    { isLoaded !== true ? 
    (<div className={styles.preloader}></div>) : 
    
    ( <div className={styles.wrapper}>
      <h2 className={styles.header}>Find Vince's books here...</h2>      
      <ul>
        {storeData.map((item) => {
          return (
            <StoreItem
              name={item.shop_name}
              key={item.id}
              logo={item.shop_logo}
              link={item.shop_link}          
              className={styles.logo}
            />
          );
        })}
      </ul>
      <h2 className={styles.content}>...or at your favorite local bookseller.</h2>
      </div>

          )}    
</>
  )
}