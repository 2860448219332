import React from "react";
import styles from "./header.module.css";
import { Menu } from "../menu";
import { SocialIcon } from "../social-icons";
// LOOK How I imported css file. it is a private css file available only inside this component because we imported here

// There is a rule , that React components have to start from Capital letter (like Header in this case)

export const Header = () => {
  // functional component dont have THIS , because it is not a class but fuinction !!!
  return (
    <header className={styles.header}>
      <div className={styles.header__wrapper}>
        <h1 className={styles.title}>VINCENT WYCKOFF </h1>
        <div className={styles.socials}>
          <SocialIcon />
        </div>
      </div>
      <Menu />
    </header>
  );
};

// There are 2 types of components' definition - function components and class components.
// Function components are functions (as you see above) with returning some JSX code
// CLASS COMPONENTS ARE echmascript like based components and I will write the same component but in
// a class way

/*
export class Header2 extends React.Component {
  // class component has this, because it is a class (and class is an object not a function)
  render() {
    return <header>Here should be our header {this.props.surname}</header>;
  }
}
*/
