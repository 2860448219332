
import React from "react";
import styles from "./slider-item.module.css";
import {SolidButton} from "../../common/buttons/solidButton";
import {StoresListPopup} from '../../common/stores-list-popup/storesPopup';

export const SliderItem = ({work}) => {
  console.log(work);

      return(
          <div className={styles.wrapper}>
            <img alt="slider_img"
                className={styles.bookImg}
                src={work.img}/>
            <div className={styles.content__wrapper}>
              <h2 className={styles.content__header}>{work.title}</h2>
              <p className={styles.content__text}>{work.content}</p>
              <div className={styles.content__btn}>
              <SolidButton 
                 handleClick={()=> {StoresListPopup(work)}}
                 label="Buy Now"
                 className={styles.button}                 
                />
                </div>
            </div>
          </div>   
      )
    }