import React from "react";
import { Link } from "react-router-dom";
import styles from "./menu-item.module.css";

export const MenuItem = (props) => {
  console.log(props);
  return (
    <li className={styles.menu_item}>
      <h3 className={styles.menu_title}>
      <Link onClick={()=> {props.onClicked()}} to={props.anchor}>{props.label}</Link>
      </h3>
    </li>
  );
};

