import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

ReactDOM.render(
    <App />,
  document.getElementById("root")
);

serviceWorker.unregister();
// It is not good practice to paste structure code in this file, for these purposes
// you should better to use App.js file

//Got it. Let's take a look at the structure again together on Saturday. I'm still a bit fuzzy on how it how links together.
