import React from "react";
import styles from "./works-item.module.css";
import {SolidButton} from "../buttons/solidButton";
import {StoresListPopup} from '../stores-list-popup/storesPopup';

export const WorksItem = (props) => {
     return (
      <li className={styles.cardBody}>
        <div>
          <img className={styles.bookImg} alt = "Book Cover" src={props.img}/>
        </div>

        <div className={styles.blurbBody}>
          <h3 className={styles.title}>{props.title}</h3>
          <p className={styles.content}>
            {props.content}
          </p>
            <div className={styles.button__wrapper}>
              <SolidButton handleClick={()=> {StoresListPopup(props)}} label="Buy Now"  className={styles.buyButton} />       
            </div>
        </div>
      </li>     
    );
  }