import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import styles from './bookslider.module.css';
import { SliderItem } from './slider-item/SliderItem';
import { workStores } from '../../data/cards';

export const BookSlider = () => {
	const arrowStyles = {
		position: 'absolute',
		zIndex: 3,
		top: 'calc(50% - 20px)',
		width: 30,
		height: 40,
		background: 'transparent',
		border: 0,
		cursor: 'pointer',
		fontSize: 20,
	};

	return (
		<Carousel
			className={styles.wrapper}
			showThumbs={false}
			statusFormatter={(current, total) => ``}
			renderArrowPrev={(onClickHandler, hasPrev, label) =>
				hasPrev && (
					<button
						type="button"
						onClick={onClickHandler}
						title={label}
						style={{ ...arrowStyles, left: 15 }}
					>
						<i className="fa fa-angle-left"></i>
					</button>
				)
			}
			renderArrowNext={(onClickHandler, hasNext, label) =>
				hasNext && (
					<button
						type="button"
						onClick={onClickHandler}
						title={label}
						style={{ ...arrowStyles, right: 15 }}
					>
						<i className="fa fa-angle-right"></i>
					</button>
				)
			}
		>
			{workStores.map((item) => {
				return <SliderItem key={item.id} work={item} />;
			})}
		</Carousel>
	);
};
