import React, {useState} from "react";
import { MenuData } from "../../../data/cards";
import { MenuItem } from "../menu-item";
import styles from "./menu.module.css";

  
const isBigScreen = () => window.innerWidth > 850 ? true : false

export const Menu = () => {

  const onResize = () => {
      const isDisplay =  isBigScreen();
      setVisibility(isDisplay);
  }

  const closeMenu = ()=> {
    const isDisplay =  isBigScreen();
    if(!isDisplay) {
      setVisibility(false);
    }
  }

  window.onresize = onResize;
  const [isVisible, setVisibility] = useState(isBigScreen());

  const getMenu = () => {
    if(isVisible) {
      return (
        MenuData.map((item) => {
          return (
            <MenuItem
              onClicked={closeMenu}
              key={item.id}
              anchor={item.anchor}
              label={item.label}
            />
          );
        })
      );
    }
  }

   return (
    <div>     
            <img 
            className={styles.hamburger_menu}             
            src="/assets/menu_white.png" 
            alt=""
            onClick={
              ()=> {
                setVisibility(!isVisible);
              }}
            />

      <ul className={styles.wrapper}>
        {
          getMenu()
        }
      </ul>
    </div>
  );
};

// Why I added key property inside map function into MenuItem,
// please read this article
// https://reactjs.org/docs/lists-and-keys.html
