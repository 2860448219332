import React from "react";
import styles from "./storesListPopup.module.css";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { workStores, storeData } from "../../../data/cards";


// invoke stores list popup when you click button on slider book item 

export const StoresListPopup = (props)=> {  
  let storeInfoObj = workStores.find(item => item.id === props.id);
  let storeInfo = storeInfoObj.storeInfo;

  const getShopList = (store) => {
    // Remember the commas between pictures, join removes it
    return storeInfo.map((shop)=> (getShop(shop))).join('');
  }

  const getShop = (shop, width) => (
    `<div style="padding: 2rem 0">
    <a href="${shop.shop_link}">
      <img  width="175" src="${shop.shop_logo}"/>
    </a>
    </div>`
)
    const MySwal = withReactContent(Swal);
     MySwal.fire({
        title: <span className={styles.alertTitle} >Choose a Store:</span>,
        confirmButtonText: <div className={styles.alertText}>Close</div>,
        confirmButtonColor: '#1C3158',
        html: `${getShopList(storeInfo)}`
      });
    }

    

