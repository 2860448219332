import React from "react";
import { BookSlider } from "../../bookslider";
import { Switch, Route } from "react-router-dom";
import styles from "./content.module.css";
import { WorksPage, Contact, EventsPage, BuyPage } from "../../pages";
import { BioCard } from "../../bio-card";
import { FeaturedEvent } from "../../featured-event";
import { Article } from "../../article";

export const Content = () => {
  return (
    <section className="appBody">
      <Switch>
        <Route exact path="/">
        <div className={styles.biowrapper}>
          <FeaturedEvent />
          </div>
          <div className={styles.biowrapper}>
          <Article />
          </div>
         <div className={styles.biowrapper}>
          <BioCard />
          </div>
          <div className={styles.sliderwrapper}>
            <BookSlider />            
          </div>        
        </Route>
        <Route exact path="/works">
          <WorksPage />
        </Route>
        <Route exact path="/buy">
          <BuyPage />
        </Route>
        <Route exact path="/events">
          <EventsPage />
        </Route>
        <Route exact path="/contact">
          <Contact />
        </Route>
      </Switch>
    </section>
  );
};
